/* COLORS */
/* Yellow Colors */
    /* background-color: rgb(255, 255, 0,0.7); */

    /* Blue Colors */
    /* background-color: rgb(0, 128, 255,0.7); */
    /* background-color: rgb(0, 128, 255,0.7); */
    
    /* Orange Color */
    /* background-color: rgb(255, 192, 0,0.7) ; */
    /* background-color:  rgb(255, 165, 0,0.7)  ; */

    /* Body Color */
    /* background-color: rgb(230, 215, 170); */

/* END COLORS */

/* needed Classes */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
h1:hover,h2:hover,h3:hover,h4:hover{
  cursor: default;
}
.bg-white{
  background-color: white;
}
.cursor_def:hover{
  cursor: default !important;
}
.click:hover{
  cursor: pointer;
}
.none {
  display:none;
} 
.noHeight{
  height: 0;
}
.noWidth{
  width:0;
}
.toUpperCase{
  text-transform: uppercase;
}
.txt_center{
  text-align: center;
}
.flex-column{
  display: flex;
  flex-direction: column;
  gap:20px;
  padding: 5px;
}
.flex-column.mid{
  justify-content: center;
  align-items: center;
}
.flex-mid{
  display: flex;
  justify-content: center;
  align-items: center;
}
.fwrap{
  flex-wrap: wrap;
}
.flex-even{
  display: flex;

  gap:5px;
  padding: 5px;
}
.flex-even.end{
  justify-content: flex-end;
}
.flex-even.extra{
  gap: 35px !important;;
}
.flex-even.mid{
  gap:0;
  justify-content: center;
  align-items: center;
  padding: 0%;
}
.flex-even.pad{
  padding: 20px !important;;
}
.flex-even>*{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-even.mid>*{
  width: auto;
}
.fwrap{
  flex-wrap: wrap;
}

.even-autoHeight>*{
  display: inline-block;
  width: calc(50% - 14px);
  padding:5px;
  margin:2px;
}
.myCustomHeight{
  width: 100%;
}
.light_border{
  border: 2px solid rgb(136,198,150,0.5);
}
.element-boxShadow{
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
}
.container-boxShadow{
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}

.middleHeader{
  margin: auto;
}
.header_startdir{
  text-align: left;
  margin:0;
}
.pos_abs{
  position: absolute;
  z-index: 100000;
}
.pos_abs_full{
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
/*  */
/* table css code */
table {
  border-collapse: collapse; /* Collapse borders into a single border */
  width: 100%; /* Set table width */
}

/* Style for table borders */
table.theme, th, td {
  border: 1px solid rgb(136,198,150); /* Set border width and color */
}

/* Optional: Add padding to cells for better spacing */
table.theme th, td {
  padding: 8px;
}

/* Optional: Add background color to alternate rows for better readability */
table.theme tr:nth-child(even) {
  background-color: #f2f2f2;
}
/*  */

/* MUI DESIGN */
.MuiList-root{
  max-height: 400px!important;
}
.logout:hover{
  cursor: pointer;
}
/*  */
/* Example Data Nav */
.exampleData-container{
  background-color: rgb(136,198,150);
  display: inline-flex;
  flex-direction: column;
  color:white;
  align-items: center;
  border-radius: 25px;
  padding:5px;
  font-size: 90%;
  
}
#root ,body{
  overflow-x: hidden;
}
*,h1{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
.App{
    position: relative;
    text-align: center;
    height: 100%;
    overflow-x: hidden;
}
.header{
    position: relative;
    background-color: rgb(136,198,150);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
    height: 110px;
}
.logout_icon-wrapper{
  color:white;
  position: absolute;
  height: 30px;
  width: 30px;
  left: 50px;
  bottom:calc(50% - 15px);
}
.header .tasks_left-wrapper{
  position: absolute;
  text-align: start;
  left:100px;
  bottom:calc(50% - 15px);

}
.header .tasks_left-wrapper.finished{
  
  bottom:calc(50% - 35px);

}
.logout_icon-wrapper:hover{cursor: pointer;}
.logout_icon-wrapper>*{
  width: 100%;
  height: 100%;
}
.navbar{
    display: flex;
    justify-content: flex-start;
    list-style: none;
    padding:10px;
    gap:10px;
}
.nav-item{
    padding:25px;
    
}
.theme-color{
  background-color: rgb(136,198,150) !important;
}
.material-form{
  width: 100%;
  max-width: 400px !important;
}

a   {
    text-decoration: none; 
    font-size: 25px;
    color:white;
    border-bottom: 1px solid rgb(136,198,150);
    
}
a:hover {
    border-bottom: 1px solid white;
}

.page-container{
    padding :50px 20px;
    display: flex;
    flex-direction: column;
    gap:20px;
    overflow-x: hidden;
    min-height: 100%;
}

.page-container > *:hover{
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.9); */
}

/* .page-container > *{
    transition: all 0.15s;
} */

.graph{
    display: flex;
    height: 400px;
    background-color: rgb(136,198,150);

    
}

.blurrBg{
    margin: 0 !important;;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top:0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.form-container{
  background-color: white;
  position: relative;
  color:white;
  display: inline-block;
  margin:auto;
  
  border-radius: 10px;
}
.form-container header{
  background-color: rgb(136,198,150);
}
.form-container >*{
  padding: 25px 50px 10px;
}
.blurrBg .form-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
    max-height: 80%;
    overflow-y: scroll;
}

form{
    display: flex;
    flex-direction: column;
    gap:5px;
    padding: 10px 0px 20px;
    color:black;
}

form button[type="submit"].MuiButtonBase-root{
  background-color: rgb(136,198,150)!important;
  color: white!important;
}
.schedule_add_remove-container{
  padding-right: 40px;
}
.ws_add-project-wrapper{
  color:rgb(136,198,150);
  display: inline-flex;
  height: 40px;
  width: 40px;
  align-self: flex-end;
 
  
}
.ws_add-project-wrapper.remove{
  color:rgb(255,0,0,0.7);
  height: 30px;
  width: 30px;
}
.ws_add-project-wrapper:hover{
  cursor: pointer;
  transform: scale(1.05);
}
.ws_add-project-wrapper>*{
  height: 100%!important;
  width: 100%!important;
}
.ws_add-project-wrapper .info-window{
  width: auto!important;
  height: auto!important;
}
.exit-form{
  position: fixed;
  top : calc(0% + 5px);
  right : calc(0% + 20px);
  font-size: 40px;

}
.exit-form:hover{
  cursor: pointer;
}
/* Projects CSS */
.projectsHome>*,.project-container>*{
    margin-top:100px;
}
/* Project Activator and ProjAct CSS */

.project_activator-container{
  text-align: left;
}
.project_activator-container > div{
  margin: 40px 0px 20px;
}
.proj_type-container{
  display: flex;
  flex-wrap: wrap;
  gap:50px;
}
.proj_act-container{
  display: flex;
  align-items: center;
  gap:10px;
  padding: 15px;
  border-radius: 5px;
}
.proj_act-container.active {
  background-color: rgb(136,198,150,0.5);
}
.proj_act-container.unactive {
  background-color: rgb(255, 123, 0,0.2);
}
/* ProjectStepTaskOrganizer */
.projectStepTaskOrganizer-container{
  border:1px solid rgb(136,198,150);
  max-height: 500px;
  min-width: 500px;
  overflow-y: scroll;
}
.organize_list_item-container{
  background-color: white;
  display: flex;
  gap:10px;
  align-items: center;
  height: auto !important;
}
.organize_list_item-container .order-number{

}
/* ProjectStepTaskOrganizer END */

/* 
.navsub{
    background-color: rgb(136,198,150);
    color: white;
    border-radius: 22px;
    padding:20px;
    font-size: 25px;
    display: flex;
    gap:25px;
}
.sub-navbar{
    border-bottom: 2px solid rgb(136,198,150);
}
.sub-navbar:hover{
    cursor: pointer;
    border-bottom: 2px solid white;
} */
/* Projects CSS END */
/* ADD COMPONENT CSS */
.add-container > .MuiButtonBase-root.MuiFab-root{
  color:rgb(136,198,150);
}
.add-container{
    z-index: 10;
    position: fixed;
    left:10px;
    bottom:10px;
}
.add-options-container{
  border:0.5px solid black;
  background-color: white;
  border-radius: 10px;
  transition: all 0.15s;
  position: absolute;
  bottom:80px;
  right:-250px;
  font-size: 22px;
  display: flex;
  flex-direction: column;

}
.add-option{
  width:280px;
  height:50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
}

.add-option>*{
  margin:0 15px;
}
.add-action-container .action{
  display: inline-block;
  font-size: 18px;
  margin:0 3px;
  
}
.add-action-container .action:hover{
  cursor: pointer;
}
/* ADD COMPONENT CSS END */

/* Adding to bootsrap */
.bi-plus-circle-fill{
  color:rgb(136,198,150);
}
.bi-plus-circle-fill:hover{

}
.bi-trash{
  background-color:rgb(255, 49, 49,0.7);
  color:white;
}
.bi-trash:hover{

}
.bi-pen{
  color: rgb(255, 255, 0,0.7);;
}
.bi-pen:hover{

}

.add-options-container>*:hover{
  background-color: rgba(0, 0, 0, 0.2);
}
.plus-container{

    background-color: rgb(136,198,150);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    padding:5px;

}
.plus-container >*{
    display: inline-block;
    border:10px solid white;
    width: 50%;
    
}
.rotate{
    transform: rotate(-90deg);
}
.circle {
    width: 50px; /* Set the circle's size */
    height: 50px; /* Set the circle's size */
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border:0.1px solid black;
    transition: all 0.15s;
  }
  .circle:hover{
    cursor: pointer;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.9);
  }
  .plus {
    width: 90%; /* Set the plus sign's width to 90% of the circle */
    height: 3px; /* Set the plus sign's height */
    background-color: rgb(136,198,150);
    position: absolute;
    top: 48%; /* Adjust the vertical position of the plus sign */
    left: 5%; /* Adjust the horizontal position of the plus sign */
    transform: rotate(-90deg);
    border-radius: 5px;
  }
  .plus2 {
    width: 90%; /* Set the plus sign's width to 90% of the circle */
    height: 3px; /* Set the plus sign's height */
    background-color: rgb(136,198,150);
    position: absolute;
    top: 45%; /* Adjust the vertical position of the plus sign */
    left: 5%; /* Adjust the horizontal position of the plus sign */
    border-radius: 5px;
  }

  .page-container.projects{
    padding-top: 50px;
  }

  .projectStep-container{
    position: relative;
    padding:20px;
    /* height: 175px;
    overflow: hidden; */
   
  }
  .projectStep-container >h1{
    font-size: 45px;
  }
  .projectStep-container h1{
    text-align: right;
  }
  .ps_quick_add-container{
    justify-content: flex-start!important;
    align-items: flex-end!important;
    gap:10px!important;
    padding-right: 25px;
  }
  .ps_quick_add-container>*{
    width: auto;
    
  }
  .quick_add_icon{
    color:white;
    background-color: rgb(136,198,150);
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }
  .quick_add_icon.FinancialConduct{
    background-color: rgba(255, 165, 0);
    width: 30px;
    height: 30px;
    margin-bottom: 15px;

  }
  .quick_add_icon.task{
    width: 25px;
    height: 25px;
  }
  .quick_add_icon svg{
    width: 100%;
    height: 100%;
  }
  /*  */
  .tasks-container{
    padding:20px 40px;
  }
  .completed_task-container{
    background-color: rgb(136,198,150,0.3);
  }
  .inprogress_task-container{
    background-color: rgb(255, 192, 0,0.3);
  }
  .tasks-container > *{
    margin-bottom:20px ;
  }
  .task-container{
    padding-bottom:10px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.15s;
    padding:5px 0px 0px 0px;
  }
  .task-closed{
    height: 46px;
    padding-bottom:0;
    overflow: hidden;
  }
  .task-container:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .task_header-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
    font-size: 20px;
    margin-bottom: 25px;
  }
  .task-duration_start-wrapper{
    display: flex;
    flex-direction: column;
    gap:5px;
    align-items: center;
  }
  .task-duration_start-wrapper.projects{
    flex-direction: row!important;
  }
  .tasks-displayer-wrapper{
    position: relative;
  }
  .tasks-displayer{
    background-color: rgb(136,198,150,0.7);
    width:100px;
    border-radius: 10px;
    position: absolute;
    z-index: 25;
    bottom:-20px;
    left:calc(50% - 50px);
    font-size: 20px;
    transition: all 0.15s ;

  }
  .tasks-displayer.open{
    transform: rotate(180deg);
  }
  .tasks-displayer:hover{
    cursor: pointer;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.9);
  }
  .idea-header{
    font-size: 45px;
  }

  .personal_subject-container{
    background-color: rgb(136,198,150);
    color:white;
    border-radius: 10px;
    
  }
  .personal_subject-container.fixed_height{
    height: 500px;
    overflow: auto;
  }

  form{
    display: flex;
    flex-direction: column;
    gap:35px;
  }




  /* dailyNotification style */

  .dragN-container{
    opacity: 0.6;
  }

  .dailyNotification-container{
    position: fixed;
    right: -300px;
    top:130px;
    border:1px solid black;
    border-radius: 5px;
    width:300px;
    background-color: white;
    z-index: 1500;
    transition: 0.3s all;
  }
  .subpage-n{
    width: 300px!important;
  }
  .dailyNotification-container.visible{
    right:0;
  }
  .dailyNotification-container .header-wrapper{
    height: 50px;
    background-color: rgb(136,198,150);
    color: white;
  }
  .dailyNotification-container>.visability{
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: rgb(136,198,150);
    
    border-top:1px solid black;
    border-left:1px solid black;
    border-bottom:1px solid black;
    color: white;
    left:-49px;
    top:-1px; /* match border */
    
  }
  .visability div{
    width: 100% !important;
    height: 100%;
    transition: 0.3s all;
  }
  .visability .visible{
    transform: rotate(180deg);
  }
  .dailyNotification-container .visability:hover{
    cursor: pointer;
  }
 
  .notification-wrapper{
    border: 2px solid black;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .notification-wrapper > *{
    padding: 2px;
  }
  .notification-name.missed{
    
    background-color: rgb(255,0,0,0.3);
  }
  .notification-done_btn{
    min-width: none;
    width: 50px;
    height: 30px;
    font-size: 12px;
  }
  .notification-done_btn.missed{
    background-color: rgb(255, 192, 0);
  }
  .notification-done_btn.during{
    background-color: rgb(136,198,150);
  }
  .display-option-container{
    overflow: hidden;
  }

  .nGroup-contaier{
    margin-bottom:30px;
    text-align: start;
  }
  .navbar-dailyNotification{
    background-color: rgb(136,198,150);
    display: flex;
    list-style-type: none;
    padding: 0px 10px;
    gap:8px;

  }
  .navbar-dailyNotification>*:hover{
    cursor: pointer;
    background-color: white;

  }

  .navbar-dailyNotification .chosen-display{
    background-color: white;
  }


  .nav-item-n{
    padding: 5px;
  }

  .slider-container-n{
    display: flex;
    width: 200%;
    position: relative;
    transition: all 0.2s;
  }
  .slider-container-n>*{
    width: 100%;
    max-height: 500px;
    overflow-y:scroll;
    padding: 0px 10px;
  }

  #sticky-element {
    position: sticky;
    width: 50px;
    height: 50px;
    background-color: blue;
    border-radius: 50%; /* Makes it a circle */
    cursor: grab; /* Change cursor on hover for draggable effect */
    user-select: none; /* Prevent text selection while dragging */
    transition: transform 0.2s ease; /* Add a smooth transition effect */
  }



  /* Idea Component Style */

  .idea-container{
    text-align: start;
    border: 2px solid rgb(136,198,150);
    border-radius: 20px;
    overflow-x: hidden;
  }
  .idea-container >*{
    padding: 10px 20px;
    background-color: white;
  }
  .idea-header-wrapper{
    background-color: rgb(136,198,150);
    color:white;
    text-align: center;
  }

  .notes-container {
    max-height: 100px;
    overflow: scroll;
  }
  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
}
.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}
.react-grid-item.cssTransforms {
    transition-property: transform;
}
.react-grid-item.resizing {
    z-index: 1;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
}

.react-grid-item.react-grid-placeholder {
    background: #000;
    opacity: 0.1;
    transition-duration: 100ms;
    z-index: 2;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
/*  */

/* Loader CSS */


.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid rgb(136,198,150);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.text {
  font-size: 24px;
  color: white;
  margin-top: 16px;
}

/* End Loader  */
/* Scheduler */
.scheduler-container{
  height: 650px;
  padding:10px;
  width:100%;
}
/* react big calendar class */

.rbc-agenda-time-cell,.rbc-event-label{
  direction: ltr;
}
.rbc-timeslot-group {
  min-height: 100px!important;
}
/* End Scheduler */

/* Chosen Form additional Css */
.daily_schedule-container{
  display: flex;
}
.daily_schedule-container >*{
  width: 100%;
}
/* END CHOSEN FORM */

/* Finance Component  */
.finance-container{
  display: flex;
  gap:5px;
  justify-content: space-around;
  flex-wrap: wrap;
  padding:10px 0px;

}
.finance-slider{
  background-color: rgb(230, 230, 230,0.3)
}
.chart-container.finance>div{
  /* padding-top:10px; */
}
.spare_money-container{
  margin-block: 50px;
  
}
.spare_money{
  font-size: 30px;
}
.spare_money .good{
  color:green;
}
.spare_money .bad{
  color:red;
}
.spare_money .bad_but_not_that{
  color:rgba(255, 165, 0);
}
.finance-category_header{
  margin-block: 50px;
}
/* END Finance */
/* Notifications Component */
.notifications_window-container{

}
.notifications_window-container>div.popUp_inputs-container{
  display: flex;
  gap: 15px;
  padding: 20px 30px;
}
.notifications_window-container>div.submit-wrapper{
  
}
.submit-wrapper>button{
  background-color: white;
}
.notifications-container{
  display: flex;
  gap:5px;
  flex-wrap: wrap;
  justify-content: space-around;
  padding:10px 0px;
}
.notifications-container > *{
  width: 80%;
}
/* End Notifications */

/* Workout Component */
.workout-container{
  padding:5px;

}
.workout-container >*{
  margin :25px 0px;
}

.active-container{
  padding:5px;
  text-align: start;
}
.rotationIfno{
  padding-left:5%;
}
.days-container{
  display: flex;
  justify-content:space-around;
  gap:5px;
}

/* end workout component */
/* Alerts component */
.alerts-container{
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  z-index: 100000;
}
/* Alerts component END */

/* Bank Report Popup Css */
.popup_report-container{
  display: flex;
  justify-content: center;
  gap:10px;
  align-items: center;
}
.report_icon_wrapper:hover{
  cursor: pointer;
}
.report_icon_wrapper{
  padding-bottom: 10px;
}
.report-container{
  background-color: rgb(52, 119, 204,0.9);
  color: white;
  padding: 20px;
  border-radius: 5px;
  width:80vw;
  min-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:20px;
}
.report-container h3{
  margin: auto;
  border-bottom: 2px solid white;
}
.bank-sumCoin-container{
  display: flex;
  align-items: center;
  justify-content: space-around;
  
}
.bank_coin-container{
  border-radius: 5px;
  background-color: white;
  color:black;
  padding:10px;
}
.positive-balance{
  color: rgb(11, 149, 9);
}
.negative-balance{
  color:red;
}
.warning-balance{
  color:rgb(255, 123, 0);
}
.invalid-balance{
 border:1px solid red;
}
.chooseCoin_balance-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:20px;
}
.bank_coinCurrency-container{
  width:500px;
}
/* END Bank Report Popup CSS */


/* MUI TABLE CSS */
.expenses-container > .MuiBox-root{
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}
/* MUI TABLE CSS END */

/* SCHEDULEDDAY COMPONENT CSS */
.ScheduledDay-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:10px;
  gap:20px;
}
.scheduledDay_timer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;
  padding: 10px;
  border-radius: 22px;
  border: 2px solid rgb(136,198,150);
  width:30%;
}

/* SCHEDULEDDAY COMPONENT CSS  END*/

/* SCHEDULEDPROJECT COMPONENT CSS */
.scheduledProjects-container{
  width: 100%;
  display: grid;
  /* grid-template-columns: 20% 80%; */
  gap:20px;
  grid-template-columns: auto 1fr;
}
/* .scheduledProjects-container > * {
  padding:10px;
} */
.sProject-container{
  width:100%;
  border-radius: 5px;
  padding:5px;
}
.sProjectStep-container{
  padding:2px 10px;
}
.sProjectStep-header{
  margin: 50px 0px;
  border-bottom: 1px solid black;
}
.sTask-container{
  padding:15px;
}
.progress_bar-container{
  display: flex;
  justify-content: center;
  padding: 5px;
}
/* SCHEDULEDPROJECT COMPONENT CSS END*/


/* TASK TIMER , TIMER HOOK CSS */
  .task_timer-container{
    position: absolute;
    display: inline-block;
    background-color: rgb(136,198,150);
    padding: 2px;
    color:white;
    border-radius: 10px;
    width: 450px;
    height: 270px;
    z-index: 100000;
    cursor: default;
  }
  .task_timer-container.fixed{
    position: fixed;
    right: 2px;
    top:30px;  
  }
  .timerhook_wrapper{
    font-size: 100px;
  }
  .task_timer-header-container{
    background-color: white;
    color:black;
    border:1px solid rgb(136,198,150);
    border-radius: 5px;
    padding-block: 5px;
  }
  .task_timer-options-container{
    display: flex;
    border-top:2px solid rgb(136,198,150);
    justify-content: space-around;
  }
  .task_timer-options-container > div ,.task_endTime_options-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5px;
  }

  .extra_time_input-wrapper{
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    min-width: 100px;
    min-height: 100px;
    background-color: rgb(136,198,150,0.8);
  }
  .extra_time_input-wrapper > *{
    color:white;
  }
  .task_endTime-container{
    background: linear-gradient(white,orange );
    padding:10px;
    border-radius: 10px;
    color:rgba(65, 52, 52, );
    min-width: 200px;
    min-height: 200px;
  }
  .task_endTime-container> div{
    margin-block: 10px;
  }
  .task_endTime_options-container{
    display: flex;
    justify-content: space-around;
    gap:20px;
    
  }
  
  .timesUp_header{
    text-align: center;
    color:red;
    padding: 5px;
  }
/* TASK TIMER , TIMER HOOK CSS END */


/* NOTIFICATIONS/ASSIGMENTS POPUP CSS */
.notifications_popup-container{
  position: relative;
  padding-bottom:30px;
  border-radius: 30px;
  overflow-y: scroll;
  width:900px ;
  max-height: 600px;
}
.grid_3c{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.flex_mid_sgap{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
}
.flex_colm_noGap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.exit_form_abs{
  position: absolute;
}
.grid_3c.notifications_popup{
  column-gap: 10px;
  row-gap: 10px;
  padding:30px;
}
.notifications_popup-container.during_day{
  background-color: white;
  border:2px solid rgb(136,198,150);
}
.notifications_popup-container.by_hour{
  background-color: white;
  border:2px solid rgb(255, 192, 0,0.8);
}
.notifications_popup-container.missed_by_hour{
  background-color: white;
  border:2px solid rgba(255, 81, 0, 0.8);
}
.notifications_popup-container>header{
  color:white;
  text-align: center;
  background-color: rgb(136,198,150);
  padding:20px 50px 20px 20px;
  font-size: 20px;
}
.notifications_popup-container.during_day>header{
  background-color: rgb(136,198,150);
}
.notifications_popup-container.by_hour>header{
  background-color: rgb(255, 192, 0);
}
.notifications_popup-container.missed_by_hour>header{
  background-color: rgba(255, 81, 0);
}
.notification_AssigmentComp-wrapper{
  background-color: white;
  border-radius: 5px;
  padding: 15px;
}

/* NOTIFICATIONS/ASSIGMENTS POPUP CSS END*/

/* WorkoutDone CSS  */
.workout_done-container{
  width: 400px !important;
}
.workout_done-container>div.options{
  padding: 10px;
  gap:10px;
}
/* WorkoutDone CSS  END*/

/* BucketOfWater CSS  */
.bucket{
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.bucket_percentage{

}
.bucket .water{
  height: 0px;
  width: 100%;
  background-color: rgb(136,198,150);
  transition: all 0.3s;
}
/* BucketOfWater CSS  END*/