@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.wave {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(136,198,150, 0.5);
    border-radius: 45%;
}

.wave1 {
    top: -5%;
    left: -5%;
    animation: rotate 5s linear infinite;
}

.wave2 {
    top: -5%;
    left: -5%;
    animation: rotate 5s linear infinite reverse;
}

.bucket .water {
    position: relative;
    /* overflow-x: hidden; */
}