.hover-container {
    display: inline-block;
    position: relative;
  }
  
  .info-window {
   
    position: absolute;
    border: 1px solid #ccc;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000000;

    width: 250px;
  }