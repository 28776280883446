.basic_table-container{
    width: 450px;
    overflow: hidden;
}
.multi_bt-container{
    display: flex;
    gap:50px;
    justify-content: center;
    padding: 5px;
    flex-wrap: wrap;
}
.bt_column-contaier{
    display: flex;
    flex-direction: column;
    gap:40px;
}