.waitinglist-container{
    background-color: white;
    overflow-y: scroll;
    border-radius: 5px;
    border:1px solid rgb(136,198,150);
}
.waitinglist-container>*{
    padding:10px;
    background-color: inherit;
}
.waitinglist-container>div.header{
    color:white;
    background-color: rgb(136,198,150);
    display: flex;
    align-items: center;
    justify-content: center;
}

.users-container>*{
    margin-block: 10px;
}
.users-container>table{
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
}
.approve_icon-wrapper:hover{
    cursor: pointer;
}
.approve_all-wrapper>button{
    color:white;
    background-color: rgb(136,198,150);
}