/* .progress-bar {
    width: 20px; 
    background-color: #f0f0f0;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column-reverse; 
    overflow: hidden; 
  }
  
  .progress-bar-inner {
    width: 10px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .progress-bar-fill {
    width: 100%;
    height: 0;
    background-color: green;
    transition: all 0.5s;
    border-radius: 20px;
  } */


  .scheduleProjects-progress-bar{


  }
  .project-circle{
    width: 48px;
    height: 48px;
  }
  
  .projectStep-circle{
    width: 40px;
    height: 40px;
  }
  .task-circle{
    width: 30px;
    height: 30px;
  }



  .circle-wrapper{
    padding:4px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgb(136,198,150);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }
  .progress-circle {
    width: 100%; 
    height: 100%; 
    background-color: white;
    border-radius: 50%;
    transition: background-color 0.3s ease; 
  }
  .pMargin_top_circle{
    margin-top: 20px;
  }
  .mid_circle{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .completed {
    background-color: rgb(136,198,150);
  }
  
  .line_progress_wrapper{
    
    padding-block: 2px;
    width:5px;
  }
  .progress-line{
    border-radius: 10px;
    background-color: rgb(136,198,150);
    width: 100%;
    transition: all 0.5s;
  }