.sch_comp-container{
    
    border:1px solid rgb(136,198,150);
    border-radius: 10px;
    overflow: hidden;
}

.sch_comp-container h3{
    height: 60px;
    margin:0;
    background-color: rgb(136,198,150);
    color:white;
    padding:10px;
}
.sch_comp-container h3:hover{
    cursor: default;
}
.school_schedule-container{
    display: grid;
    grid-template-columns: auto repeat(7, 1fr);
    
}
.hours-wrapper{
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    
}
.hours-wrapper > h3{
    background-color: rgba(255, 145, 0, 0.8);
}
.hours-wrapper>.hour_wrapper{
    /* height:50px; */
    border:1px solid black;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.daily_hours_schedule-container div{
    transition: all 0.3s;
    height: 0px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    overflow-y: scroll;
   
}
.daily_hours_schedule-container div:hover{
    filter: brightness(80%);
    transform: scale(1.01);
}

.sleep-wrapper{
   background-color: rgb(9,31,70);
    
}
.inbetween-wrapper{
    background-color: rgba(255, 145, 0, 0.8);
    flex-direction: column;
}