/* PageSlider Component */
.pageslider-container{
    overflow-x: hidden;
    width:100%;
    /* max-width: calc(50vw - 50px); */
    border-radius: 22px;
  }
  .pageslider-nav{
    display: flex;
    justify-content: flex-start;
    gap:25px;
    list-style: none;
    /* background-color: rgb(52, 119, 204); */
    background-color: rgb(136,198,150);
    color: white;
    border-radius: 22px;
    padding:20px;
    font-size: 25px;
  }
  .pageslider-nav >*{
    cursor: pointer;
    /* border-bottom: 2px solid rgb(52, 119, 204); */
    border-bottom: 2px solid rgb(136,198,150);
  }
  .pageslider-nav >*:hover{
    cursor: pointer;
    border-bottom: 2px solid white;
  }
  .pageslider-nav > li.nav_chosen{
    border-bottom: 2px solid white;
  }
  .pages-container{
    display: flex;
    position: relative;
    transition: all 0.2s;
  }
  .pages-containerr>*{
    width: 100%!important;
  }
  .page-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding:10px;
    gap:5px;
  }
  .pageHeading{
    padding:15px 15px 0px;
    text-align: center;
  }
  .pageSubHeader{
    padding:0px 15px 15px;
    font-size: 18px;
  }
  /* PageSlider END */