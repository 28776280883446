.yearlyTimeLine-container{
    border:3px solid rgb(136,198,150);
    border-radius: 8px;
   
}

.timeline-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: rgb(136,198,150,0.5);
    overflow-x: auto;
  }
  
  .month-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
 
  }
  
  .month {
    padding: 10px ;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    text-align: center;
  }
  

  .projects_timeline-container{
    position: relative;
    height: 500px;
    overflow: hidden;
  }
  .project_tl-container{
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transition:all 0.3s;
  }

  .project_tl-container .name{
    padding: 5px;
    position: absolute;
    /* height: 35px; */
    max-height: 70px;
    background-color: white;
    border: 1px solid rgb(136,198,150);
    border-radius: 5px;
    top:-17.5px;
    /* overflow-y: scroll; */
    pointer-events: auto;
  }
  .project_tl-container:hover{
    transform: scale(1.05);
    z-index: 100;
    
  }
 

  

  